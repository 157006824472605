/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useRef, useState } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { StaticQuery, graphql, Link, useStaticQuery } from 'gatsby';
import StyledSpaceSystemGrid from './Grids/SpaceSystemGrid';
import { ThreeColumnGridWrapper } from './TextSections';
import { Arrow } from '../assets/VectorAssets';
import placeholderContent from '../data';

const HomeSpaceSystem = () => {
  const data = useStaticQuery(
    graphql`
      query {
        systemHero: file(name: { eq: "hero-system" }) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  const { spaceDesignSystem: system } = placeholderContent;
  return (
    <section
      sx={{
        minHeight: '400px',
        position: 'relative',
        bg: 'primary',
        overflow: 'hidden',
      }}
    >
      {/* Absolutely positioned text section */}
      <div
        sx={{
          // backgroundColor: ['blue', 'purple', 'red', 'pink', 'green'],
          position: ['block', 'block', 'block', 'absolute'],
          top: 0,
          width: '100%',
          h2: {
            color: 'background',
          },
        }}
      >
        <ThreeColumnGridWrapper
          className="sectionHook"
          sx={{
            bg: '',
            gridTemplateColumns: ['auto', 'auto', 'auto', 'auto', 'repeat(3, 1fr)'],
            gridGap: [0, 0, 0, 0, '55px'],
            pt: ['80px', '80px', '80px', '40px', '120px'],
          }}
        >
          {/* small column, only title */}
          <div>
            <Styled.h2>{system.title}</Styled.h2>
          </div>
          {/* Divide up the remaining two columns */}
          <div
            sx={{
              // border: '1px solid yellow',
              width: ['auto', 'auto', 'auto', '50%', 'auto'],
            }}
          >
            <Styled.p
              sx={{
                color: 'background90',
                lineHeight: '32px',
                marginBotom: 0,
                pr: [0, 0, 0, 0, '80px'],
              }}
            >
              {system.subHeadline}
            </Styled.p>
          </div>
        </ThreeColumnGridWrapper>
      </div>

      <StyledSpaceSystemGrid
        sx={{
          color: 'background',
          position: 'relative',
        }}
      >
        {/* White Block */}
        <div sx={{ gridArea: 'block-0', bg: 'background' }} />

        {/* Color Blocks */}
        {system.blocks.map((x, i) =>
          x.link ? (
            <Link
              to={`/system/${x.link}`}
              key={i}
              sx={{
                variant: 'text.removeLinkStyles',
                position: 'relative',
                color: 'inherit',
                padding: '24px',
                gridArea: `block-${i + 1}`,
                backgroundColor: `${x.hex}`,
                svg: {
                  opacity: '0',
                  transition: '.3s ease opacity',
                },
                '&:hover': {
                  svg: {
                    opacity: '1',
                  },
                },
              }}
            >
              <Styled.h4>{x.headline}</Styled.h4>
              <span
                sx={{
                  position: 'absolute',
                  bottom: '24px',
                  right: '24px',
                  mb: '0',
                  display: 'inline-block',
                  path: {
                    fill: 'background',
                  },
                }}
                alt="This link opens in a new tab"
              >
                <Arrow />
              </span>
            </Link>
          ) : (
            <div
              key={i}
              sx={{
                variant: 'text.removeLinkStyles',
                color: 'inherit',
                padding: '24px',
                gridArea: `block-${i + 1}`,
                backgroundColor: `${x.hex}`,
              }}
            >
              <Styled.h4>{x.headline}</Styled.h4>
            </div>
          )
        )}

        {/* Image */}
        {/* <Image fluid={data.systemHero.childImageSharp.fluid} sx={{ gridArea: 'image-1' }} /> */}
        <BackgroundImage fluid={data.systemHero.childImageSharp.fluid} sx={{ gridArea: 'image-1' }} />
      </StyledSpaceSystemGrid>
    </section>
  );
};

export default HomeSpaceSystem;
