/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Arrow } from '../assets/VectorAssets';
import media from './media';

const ControlWrapper = styled.div`
  position: absolute;
  top: calc(40vw - 5rem);
  top: calc(25vh);
  right: 0;
  display: flex;
  z-index: 9;
  transform: translateY(-50%);
  transform: translateY(-50%) rotate(90deg) translateY(-23px);
  height: 72px;

  /* resize for desktop */
  ${media.lg`
    top: 40vw;
    top: calc(2 * 210px);
    top: calc(2 * 25vh);
    height: initial;
    width: 20vw;
    justify-content: center;

    right: 0;
    transform: translateY(-50%) rotate(90deg) translateY(-25%);
    /* transform: translateY(-50%) rotate(90deg) translateY(-23px); */
    transform: none;
    /* move it vertially center */
    transform: translateY(-50%);
    transform: translateY(-50%) rotate(90deg);
  `}

  /* ${media.xl`
    width: calc(1400px * .2);
    right: calc((100vw - 1400px)/ 2);
    /* transform: none; */
  `} */
`;

const HeroArrowButton = styled.button`
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border: none;
  background: none;
  line-height: 12px;

  svg {
    height: 12px;
    width: auto;

    g {
      fill: ${props => props.theme.colors.background};
      opacity: 0.8;
    }
  }

  &:hover {
    cursor: pointer;

    svg g {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;

    svg g {
      opacity: 0.4;
    }
  }

  /* resize for desktop */
  ${media.lg`
    /* line-height: 24px;
    font-size: 24px; */
    padding: 2rem 1rem;
    svg {
      height: 24px;
    }
  `}
`;

const HeroControls = ({ value, inc, dec }) => (
  <ControlWrapper
    sx={{
      // bg: ['red', 'pink', 'green', 'indianred', 'purple'],
      button: {},
    }}
  >
    <HeroArrowButton
      sx={{
        svg: {
          transform: 'scaleX(-1)',
        },
      }}
      disabled={value === 0}
      onClick={() => {
        dec();
      }}
    >
      <Arrow />
    </HeroArrowButton>
    <HeroArrowButton
      disabled={value === 2}
      onClick={() => {
        inc();
      }}
    >
      <Arrow />
    </HeroArrowButton>
  </ControlWrapper>
);

HeroControls.propTypes = {
  value: PropTypes.any,
  inc: PropTypes.any,
  dec: PropTypes.any,
};
export default HeroControls;
