export default {
  title: 'what drives our product',
  blocks: [
    {
      headline: 'instil trust',
      copy: 'Be clear and transparent in communication at all touchpoints in our product.',
    },
    {
      headline: 'facilitate learnability',
      copy: 'Design to user’s natural behaviour through the use of familiar design patterns.',
    },
    {
      headline: 'add delight',
      copy: 'Find delight in empowering our users to feel prepared and productive.',
    },
    {
      headline: 'make it beautiful',
      copy: 'Create aesthetically pleasing and innovative designs to make our users happy.',
    },
    {
      headline: 'boost efficiency',
      copy: 'Provide a feature rich experience to help our users work faster and smarter. ',
    },
    {
      headline: 'synthesise harmony',
      copy: '​​Design consistent and persistence patterns to harmonize the cross-platform user experience.',
    },
  ],
};
