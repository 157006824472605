import hero from '../assets/hero/hero-system.jpg'

export default {
  title: 'space design system',
  subHeadline:
    'Our Space Design is the single source of truth for patterns and principles that are constantly moving and shaping the modern experiences for our users and customers.  Space design is an adaptable and reusable system that allows both designers and developers to focus on building with matters most without managing the complexities of a design system, design patterns and libraries.',
  blocks: [
    {
      headline: 'System Principles',
      hex: '#359fcd',
      link: 'principles',
    },
    {
      headline: 'Visual Guidelines',
      hex: '#113fcc',
      link: 'visual-guidelines',
    },
    {
      headline: 'Voice, Tone & Style',
      hex: '#2a81cc',
      link: 'content/guidelines',
    },
    {
      // headline: 'Components Library',
      headline: '',
      hex: '#359fcd',
    },
  ],
  blocksRemix: [
    'System Principles',
    'Visual Guidelines',
    'Voice, Tone & Style',
    'Components Library',
  ],
  image: hero,
}
