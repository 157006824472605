/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useRef, useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
// import { useSpring, useTrail, animated, config } from 'react-spring';
import { useWindowScroll, useCounter, useToggle } from 'react-use';
import BackgroundImage from 'gatsby-background-image';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import GenericWrapper from '../Wrapper';
import HomeHeroGrid, { HomeHeroGridWrapper } from '../HomeHeroGrid';
import placeholderContent from '../../data';
import { Arrow } from '../../assets/VectorAssets';
import HeroControls from '../HeroControls.js';
import HeroCTA from '../HeroCTA.js';

const HeroIndex = () => {
  // Refs
  const heroRefs = useRef({});
  const [value, { inc, dec }] = useCounter(0, 2, 0);
  // Content
  const { hero } = placeholderContent;

  return (
    <>
      <HeroControls value={value} inc={inc} dec={dec} />
      <div
        className="control-align-test"
        sx={{
          width: '24px',
          height: '100vh',
          position: 'fixed',
          top: '0',
          right: '24px',
          backgroundColor: 'rgba(255,255,255,0.5)',
          zIndex: '99999',
          // debug
          // opacity: '0',
          display: 'none',
        }}
      />

      <HomeHeroGridWrapper
        sx={{
          bg: 'text',
        }}
      >
        {hero.map((item, j) => {
          const { headline, title, images, slider } = item;
          const { large: imgLarge, small: imgSmall } = images;

          const heroClassnames = classNames({
            animated: true,
            abs: true,
            active: j === value,
            prev: j < value,
          });

          return (
            <HomeHeroGrid
              key={j}
              iteration={j + 1}
              sx={{
                overflow: 'hidden',
                // variant: 'invert',
                color: 'background',
              }}
              className={heroClassnames}
              ref={node => (heroRefs.current[j] = node)}
            >
              {/* Color Blocks */}
              {new Array(4).fill('empty').map((trailItem, i) => (
                <div
                  key={i}
                  sx={{
                    gridArea: `block-${i + 1}`,
                    bg: theme => {
                      if (j === 0) {
                        return `${theme.colors.scale.tertiary[i]}`;
                      }
                      if (j === 1) {
                        return `${theme.colors.scale.primary[i]}`;
                      }
                      if (j === 2) {
                        return `${theme.colors.scale.secondary[i]}`;
                      }
                    },
                    transitionDelay: `${50 * i}ms!important`,
                  }}
                />
              ))}
              {/* Large Photo */}
              <div
                sx={{
                  variant: 'backgroundImage',
                  gridArea: 'image-1',
                  backgroundImage: `url(${imgLarge})`,
                  transitionDelay: `250ms!important`,
                }}
              />
              {/* Small Photo */}
              <div
                sx={{
                  variant: 'backgroundImage',
                  gridArea: 'image-2',
                  backgroundImage: `url(${imgSmall})`,
                }}
              />
              {/* Content */}
              <HeroCTA title={title} headline={headline} />
            </HomeHeroGrid>
          );
        })}
      </HomeHeroGridWrapper>
    </>
  );
};

export default HeroIndex;
