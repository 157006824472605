/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import { Link } from 'gatsby';
import GenericWrapper from './Wrapper';
import ArrowButton from './ArrowButton';
import { NukaComposed } from './ComposedCarousel';
import placeholderContent from '../data';

const HomeTeam = () => {
  const { team } = placeholderContent;
  return (
    <GenericWrapper
      id="team"
      sx={{
        py: ['80px', '80px', '80px', '120px'],
      }}
    >
      <div
        sx={{
          display: ['block', 'block', 'block', 'flex'],
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          mb: '40px',
          button: {
            display: ['none', 'none', 'none', 'flex'],
          },
        }}
      >
        <Styled.h2 sx={{ mb: '0', maxWidth: '240px' }}>{team.title}</Styled.h2>
        <Link sx={{ textDecoration: 'none' }} to="/team">
          <ArrowButton>{team.blocks[0].title}</ArrowButton>
        </Link>
      </div>

      <NukaComposed />

      <Link
        sx={{
          textDecoration: 'none',
          display: ['flex', 'flex', 'flex', 'none'],
          mt: '40px',
          '&:hover svg path': {
            fill: 'secondary',
          },
        }}
        to="/team"
      >
        <ArrowButton>{team.blocks[0].title}</ArrowButton>
      </Link>
    </GenericWrapper>
  );
};

export default HomeTeam;
