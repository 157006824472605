/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import { PhiloSubGrid, TextSectionGeneric } from './TextSections';
import placeholderContent from '../data';

const { philosophy, product } = placeholderContent;

const HomeDesign = () => {
  const foo = 'foo';
  return (
    <TextSectionGeneric title={philosophy.title}>
      <div
        sx={{
          gridColumn: 'span 2',
          p: {
            color: 'bodyText',
          },
        }}
      >
        <Styled.p sx={{ mb: '55px' }}>{philosophy.subheadline}</Styled.p>
        <PhiloSubGrid>
          {philosophy.blocks.map((x, i) => (
            <div key={i}>
              <Styled.h3>{x.headline}</Styled.h3>
              <Styled.p sx={{ color: 'inherit' }}>{x.copy}</Styled.p>
            </div>
          ))}
        </PhiloSubGrid>
      </div>
    </TextSectionGeneric>
  );
};

const HomeProduct = () => {
  const bar = 'bar';
  return (
    <TextSectionGeneric title={product.title}>
      {product.blocks.map((x, i) => (
        <div key={i}>
          <Styled.h3>{x.headline}</Styled.h3>
          <Styled.p sx={{ color: 'bodyText' }}>{x.copy}</Styled.p>
        </div>
      ))}
    </TextSectionGeneric>
  );
};

export { HomeDesign, HomeProduct };
