/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useRef, useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { useTrail } from 'react-spring';
import classNames from 'classnames';
import Layout from '../components/layout';
import placeholderContent from '../data';
import QuoteSection from '../components/QuoteSection';
import HeroIndex from '../components/Sections/HeroIndex';
import HomeTeam from '../components/HomeTeam';
import { HomeDesign, HomeProduct } from '../components/HomeTextSections';
import HomeSpaceSystem from '../components/HomeSpaceSystem';

// Data
const HOME_SECTIONS_QUERY = graphql`
  query {
    site {
      siteMetadata {
        emptyQuery
      }
    }
  }
`;

const IndexPage = () => {
  // Springs

  const trail = useTrail(4, {
    opacity: 1,
    transform: 'translateY(0)',
    from: {
      opacity: 0,
      transform: 'translateY(100px)',
    },
  });

  // Refs
  const scrollRef = useRef(null);

  return (
    <StaticQuery
      query={HOME_SECTIONS_QUERY}
      render={data => {
        const { hero, philosophy, product, spaceDesignSystem: system, team, quotes: staticQuotes } = placeholderContent;
        return (
          <Layout>
            <div ref={scrollRef} sx={{ overflowX: 'hidden' }}>
              <HeroIndex />

              {/* Philosophy */}
              <HomeDesign />

              {/* Quote 1 */}
              <QuoteSection copy={staticQuotes[0].quote} author={staticQuotes[0].author} scale="accent" />

              {/* Product */}
              <HomeProduct />

              {/* Space System, new */}
              <HomeSpaceSystem />

              {/* Quote 2 */}
              <QuoteSection copy={staticQuotes[1].quote} author={staticQuotes[1].author} scale="accent" altLayout />

              {/* Team */}
              <HomeTeam />
            </div>
          </Layout>
        );
      }}
    />
  );
};

export default IndexPage;

/**
 * @done
 * add photos // raw
 * remove numbs on grid
 * remove debug styles
 * clean existing typogrpahy
 * build new typestyles
 * build responsive quote section
 * build responsive system
 * build responsive footer
 * update desktop nav
 * update mobile nav
 * install theme ui
 * apply to content sections
 * @todo
 * add arrows to links
 * add arrows to cta sections
 * build responsive copy section
 * review mock content
 * refactor box => links
 * add scroll spy
 */
