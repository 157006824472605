/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useSpring, animated } from 'react-spring';
import media from './media';
import HeroGrid from './HeroGrid';

const HomeHeroGridWrapper = styled.section`
  height: 100vh;
  overflow: hidden;
  background: INDIANRED;

  /* Desktop */
  ${media.lg`
    /* margin-top: 55px; */
    height: 80vw;
    /* max-height: min(900px, 80vw); */
    height: calc(210px * 4);
    /* margin-top: 55px; */
    
    /* new */
    height: 100vh;
  `}

  /* ${media.xl`
    height: calc(225px * 4);
  `} */
`;

const HomeHeroGrid = styled(HeroGrid)`
  /* all of thse are animated/absolute */
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 10px;
  right: 0;
  opacity: 1;

  ${media.lg`
    /* top: 55px; */
  `}

  /*
   *
   * A N I M A T E D
   * all tnese children will be animated
   **/
  
  /* Set Default State */
  > * {
    color: poop;
    transition: 500ms all ease;
    transform: translateY(100%);
    opacity: 0;
  }

  /* Text */
  > .cta {
    transform: translateY(100px);
  }

  /* SHARED */
  &.active, 
  &.prev {
    /* children */
    > * {
      transform: translateY(0);
      opacity: 1;
    }

  }

  /* ACTIVE */
  &.active {
    /* this is the defualt */
    /* opacity: 1; */

    > * {
      transform: translateY(0);
      opacity: 1;
    }

    > .cta {
      transform: translateY(0);
      transition: 700ms all ease;
    }
  }

  /* PREVIOUS */
  &.prev {
    /* hide them all together */
    /* opacity: 0; */
    transition: 600ms all ease-in-out;
    /* opacity: 0.5; */

    > * {
      transition: 400ms opacity ease-out, 1200ms transform ease-in;
      transform: translateY(-100%);
      opacity: 0;
    }

    /* Move Up */
    > .cta {
      transition: 300ms all ease-out;
      transform: translateY(-100px);
      opacity: 0;
      /* opacity: 0.5; */
    }
  }
  
  /*
   *
   * ITERATIONS
   *
  **/

  /* Purple */
  ${props =>
    props.iteration === 1 &&
    css`
      grid-template-areas:
        'cta      cta       cta'
        'cta      cta       cta'
        'cta      cta       cta'
        'block-1  .         .'
        'block-2  block-3   .'
        'image-1  image-1   image-1';

      ${media.md`
        grid-template-areas:
        'cta      cta       cta     cta'
        'cta      cta       cta     cta'
        'cta      cta       cta     cta'
        'block-1  .         .       image-2'
        'block-2  block-3   .       block-4'
        'image-1  image-1   image-1 .';
      `}

      ${media.lg`
        /* padding-top: 55px; */
        grid-template-areas:
          '.        .         .         .         .'
          'block-4  cta       cta       cta       cta'
          'image-2  cta       cta       cta       cta'
          'block-1  block-2   block-3   image-1   image-1';
      `}
    `}

  /* Blue */
  ${props =>
    props.iteration === 2 &&
    css`
      grid-template-areas:
        'cta      cta       cta'
        'cta      cta       cta'
        'cta      cta       cta'
        '.        block-2   .'
        'block-1  .         .'
        'image-1  image-1   block-3';

      ${media.md`
        grid-template-areas:
        'cta      cta       cta   cta'
        'cta      cta       cta   cta'
        'cta      cta       cta   cta'
        '.        block-2   . block-4'
        'block-1  .         . image-2'
        'image-1  image-1   block-3 .';
      `}

      ${media.lg`
        grid-template-areas:
        '.        .         .         .         .'
          'image-2  cta       cta       cta       cta'
          'block-1  cta       cta       cta       cta'
          'block-2  image-1   image-1   block-4   block-3';
      `}
    `}
    
  /* Orange */
  ${props =>
    props.iteration === 3 &&
    css`
      grid-template-areas:
        'cta      cta       cta'
        'cta      cta       cta'
        'cta      cta       cta'
        '.        .         image-1'
        '.        block-1   image-1'
        'block-2  block-3   image-1';

      ${media.md`
        grid-template-areas:
        'cta      cta       cta   cta'
        'cta      cta       cta   cta'
        'cta      cta       cta   cta'
        '.        .         block-4           .'
        'image-2        block-1   .           image-1'
        'block-2  .   block-3     image-1';
      `}

      ${media.lg`
        grid-template-areas:
        '.        .         .         .         .'
          'block-1  cta       cta       cta       cta'
          'block-2  cta       cta       cta       cta'
          'image-2  block-3   image-1   image-1   block-4';
      `}
    `}
`;

export { HomeHeroGridWrapper, HomeHeroGrid };
export default HomeHeroGrid;
