export default {
  title: 'What drives our design',
  subheadline:
    'The Sprinklr Product Design team thrives to make Sprinklr the world’s most loved enterprise product by working with our users each and every day. We love what we do and we want our users to love Sprinklr. Here is what we believe in:',
  blocks: [
    {
      headline: 'be a craftsman',
      copy:
        'Like an artisan crafts his art with perfection, at every stage of design process we take pride in crafting things with utmost care, accuracy and thoughtfulness. We honor our skill with an uncompromising attention to detail.',
    },
    {
      headline: 'be the user',
      copy:
        'We empathize with our users across entire organizations to understand their day-to-day challenges, what makes them successful and how we can help them excel in their roles.',
    },
    {
      headline: 'be industry leaders',
      copy:
        'Defining industry standards by combining innovative technology with user-centric design practices to create a consistent and innovative user experience.',
    },
  ],
};
