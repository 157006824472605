import philosophy from './index-design'
import product from './index-product'
import system from './index-system'
import hero1small from '../assets/hero/hero_home-square-1.jpg'
import hero1large from '../assets/hero/hero_home-wide-1.jpg'
import hero2small from '../assets/hero/hero_home-square-2.jpg'
import hero2large from '../assets/hero/hero_home-wide-2.jpg'
import hero3small from '../assets/hero/hero_home-square-3.jpg'
import hero3large from '../assets/hero/hero_home-wide-3.jpg'

const hero = [
  // {
  //   title: 'We are hiring!',
  //   headline:
  //     'People never forget how you make them feel. Help us own the experience!',
  //   images: {
  //     small: hero1small,
  //     large: hero1large,
  //   },
  // },
  {
    title: 'Design Thinking',
    headline: 'Designing the world’s most loved enterprise platform',
    images: {
      small: hero1small,
      large: hero1large,
    },
  },
  {
    title: 'Design System',
    headline:
      'create innovative software through consistency, empathy, and precision',
    images: {
      small: hero2small,
      large: hero2large,
    },
  },
  {
    title: 'Design Team',
    headline:
      'create products that are not only beautiful, but also intuitive and useful',
    images: {
      small: hero3small,
      large: hero3large,
    },
  },
]

const quotes = [
  {
    author: 'Ragy Thomas, Founder and CEO ',
    quote:
      '“Our vision — to be the world’s _most loved_ enterprise software company”',
  },
  {
    author: 'Ryan Parr, VP of Design',
    quote: '“Our design mantra — Celebrate our users in _everything_ we do.”',
  },
]

const placeholderContent = {
  hero,
  philosophy,
  product,
  spaceDesignSystem: system,
  team: {
    title: 'Having fun is part of the experience',
    blocks: [{ title: 'Meet the Team' }],
  },
  quotes,
}

export default placeholderContent
