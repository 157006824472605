import styled from '@emotion/styled';
import media from '../media';

const StyledSpaceSystemGrid = styled.div`
  display: grid;

  /* Grid Styles */
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 33.333vw);

  /* Grid Layout */
  grid-template-areas:
    'block-1 block-1 .'
    'block-2 block-3 block-3'
    'block-4 block-4 block-0'
    'image-1 image-1 image-1';
  
  /* 768 */
  ${media.md`
    /* Grid Styles */
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 16.667vh);
    /* Grid Layout */
    grid-template-areas:
    '. block-1 block-1 .'
    'block-2 . block-3 block-3'
    '. block-4 block-4 .'
    'image-1 image-1 . block-0';
  `}

  ${media.lg`
    /* Grid Styles */
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 25vh);
    
    /* Grid Layout */
    grid-template-areas:
    '.       . . block-2 block-0'
    '.       . . block-3 block-4'
    'block-1 . . image-1 image-1';
    
    /* Max Width */
    /* max-width: 1400px;
    max-height: 900px; */
    margin: 0 auto;
  `}

  /* ${media.xl`
    grid-template-rows: repeat(3, 225px);
  `} */
`;

export default StyledSpaceSystemGrid;
